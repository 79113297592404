var fixedheader = {
    init: function(){

        // var screenwidth = $(window).outerWidth()
        var headerheight = $('header').outerHeight();
		
		// $('.contentwrapper').css('margin-top',headerheight);    

 		// if (screenwidth >= 1000) {    
			$(window).bind('scroll', function () {
				var num = 50;
				if ($(window).scrollTop() > num) {
					$('header').addClass('shrink');
				} else {
					$('header').removeClass('shrink');
				}
			});
		// } 
		// else { 
		// 	$('header').removeClass('shrink');
		// } 
    }
}